import ViewContainer from "@components/Common/ViewContainer/ViewContainer";
import CountUpWithSuffix from "@components/Modules/CountUpWithSuffix/CountUpWithSuffix";
import Row from "@components/Modules/Utils/Row/Row";
import LongFormSummaryArrow from "@components/UI/Icons/LongFormSummaryArrow";
import { ParagraphProps } from "@model/paragraphs.model";
import { Box, Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useRouter } from "next/router";
import React from "react";
import styles from "./LongFormInfoSummary.module.scss";
import { LongFormInfoSummaryProps } from "./LongFormInfoSummary.props";

const LongFormInfoSummary: React.FC<
  ParagraphProps<LongFormInfoSummaryProps>
> = ({ fieldData, node, isModal }) => {
  const { field_title, field_description, field_items } = fieldData;

  const isLongForm = !isModal;
  const router = useRouter();

  return (
    <Box className={`${styles.longFormSummary} ${isModal && styles.isModal}`}>
      <Container maxWidth={false} disableGutters>
        <Row justifyContent="flex-end">
          <Grid item xs={12} lg={isLongForm ? 7.7 : 12}>
            <Box
              className={`${styles.borderedWrapper} ${isLongForm && styles.isLongForm
                }`}
            >
              {field_title && (
                <Box className={styles.headerBox}>
                  <Typography variant="h3">
                    {field_title}
                  </Typography>
                </Box>
              )}
              {field_items?.length ? (
                <Row>
                  {field_items.map((item, idx) => (
                    <Grid
                      item
                      xs={12}
                      lg={field_items.length > 2 ? 4 : 6}
                      key={idx}
                    >
                      <Box className={styles.numberBox}>
                        <Box className={styles.largeNumber}>
                          <ViewContainer>
                            {(isInView) => (
                              <CountUpWithSuffix
                                end={item.field_number}
                                suffix={item.field_suffix || ""}
                                prefix={item.field_prefix || ""}
                                isInView={isInView}
                                routerLocale={router.locale}
                              />
                            )}
                          </ViewContainer>
                        </Box>
                        <Typography
                          variant="bodyM"
                          component={"div"}
                          className={styles.smallText}
                          dangerouslySetInnerHTML={{
                            __html: item.field_description?.processed,
                          }}
                        ></Typography>
                      </Box>
                    </Grid>
                  ))}
                </Row>
              ) : null}
              {field_description && (
                <Box className={styles.descriptionBox}>
                  <LongFormSummaryArrow />
                  <Typography
                    variant="bodyM"
                    component={"div"}
                    dangerouslySetInnerHTML={{
                      __html: field_description.processed,
                    }}
                  ></Typography>
                </Box>
              )}
            </Box>
          </Grid>
        </Row>
      </Container>
    </Box>
  );
};

export default LongFormInfoSummary;
