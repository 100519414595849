import { absoluteUrl } from '@helper/url';
import Image from 'next/image';
import { FC, useContext } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Row from '@components/Modules/Utils/Row/Row';
import SocialShare from '@components/Modules/Utils/SocialShare/SocialShare';
import { DarkModeContext } from '@contexts/darkMode';
import { formatDate } from '@helper/date';
import useWindowSize, { WindowSize } from '@hooks/useWindowSize';

import styles from '@components/Paragraph/Hero/Hero.module.scss';
import { SlideItemProps } from '@components/Paragraph/Hero/Hero.model';
import { getHeroImage } from '@components/Paragraph/Hero/Hero';


const SlideArticle: FC<SlideItemProps> = ({ slide, node }) => {
  const { darkMode } = useContext(DarkModeContext);
  const size: WindowSize = useWindowSize();

  const { field_title, field_description, field_immagine, field_logo_hero } = slide

  const imageSlide: string = getHeroImage(slide, darkMode, size);
  const logoImage: string = field_logo_hero?.field_media_image?.uri?.url;
  const date: string = formatDate(node?.created, node.langcode);

  return (
    <Box className={styles.heroWrapper}>
      <Box className={styles.heroWrapperContent}>
        <Container maxWidth={false} className={styles.container}>

          <Row className={styles.row}>
            <Grid item xs={false} lg={1} />
            <Grid className={`${styles.content} swiper-no-swiping`} item xs={12} lg={7} >
              {logoImage && (
                <Image
                  className={styles.logoImage}
                  src={absoluteUrl(logoImage)}
                  width={field_logo_hero?.field_media_image?.resourceIdObjMeta?.width}
                  height={field_logo_hero?.field_media_image?.resourceIdObjMeta?.height}
                  alt={`logo hero`}
                  quality={100}
                  priority
                />
              )}

              <Typography
                className={`${styles.title} swiper-no-swiping`}
                variant={"h3"}
                component="h1"
                mt={4}
              >
                {field_title}
              </Typography>

              {field_description?.value && (
                <Typography
                  className={styles.subtitle}
                  sx={{
                    mb: 0,
                    '& *': {
                      '@media (min-width: 1024px)': {
                        fontSize: '1.2rem',
                        lineHeight: '1.5',
                        fontWeight: '400',
                      }
                    }
                  }}
                  dangerouslySetInnerHTML={{
                    __html: field_description?.value,
                  }}
                  variant="bodyL"
                  component="div"
                />
              )}

              <Typography
                className={styles.desc}
                sx={{ display: "inline-block" }}
                variant="bodyS"
                component="div"
                mb={30}
              >
                {date}
              </Typography>
              <SocialShare node={node} />

            </Grid>
          </Row>

        </Container>
      </Box>

      {field_immagine && (
        <Image
          className={styles.heroImage}
          width={field_immagine?.field_media_image?.resourceIdObjMeta?.width}
          height={field_immagine?.field_media_image?.resourceIdObjMeta?.height}
          src={absoluteUrl(imageSlide)}
          alt={field_title}
          quality={90}
          priority
        />
      )}
    </Box>
  );
}

export default SlideArticle