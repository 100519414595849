import React from "react";

interface TriangleProps extends React.SVGProps<SVGSVGElement> {}

export default function LongFormTriangle(props: TriangleProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      {...props}
    >
      <path
        d="M13.5 7.13397C14.1667 7.51887 14.1667 8.48112 13.5 8.86602L1.5 15.7942C0.833334 16.1791 2.11047e-07 15.698 2.01867e-07 14.9282L3.66308e-08 1.0718C2.7451e-08 0.301997 0.833333 -0.179129 1.5 0.205771L13.5 7.13397Z"
        fill="#30B762"
      />
    </svg>
  );
}
