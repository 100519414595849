import ViewContainer from "@components/Common/ViewContainer/ViewContainer";
import { getThumbnailUrl } from "@helper/media";
import { DrupalSimpleTextField } from "@model/fields.model";
import { ParagraphProps } from "@model/paragraphs.model";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import Image from "next/image";
import { useRouter } from "next/router";
import { useRef } from "react";
import styles from "./LongFormMainContent.module.scss";
import LongFormMainContentLeftDown from "./LongFormMainContentLeftDown";

interface LongFormMainContentProps {
  behavior_settings: any;
  field_title: DrupalSimpleTextField;
  field_description: any;
  field_subtitle: DrupalSimpleTextField;
  field_item: any;
  field_image: any;
}

const LongFormMainContent: React.FC<
  ParagraphProps<LongFormMainContentProps>
> = ({ fieldData, isModal }) => {
  const router = useRouter();
  const isMobile: boolean = useMediaQuery(useTheme().breakpoints.down("lg"));

  const countUpRef = useRef(null);

  const rightImage = {
    url: getThumbnailUrl(fieldData.field_image),
    ...fieldData.field_image?.field_media_image?.resourceIdObjMeta,
  };

  const leftImage = {
    url: getThumbnailUrl(fieldData?.field_item?.field_image),
    ...fieldData.field_item?.field_image?.field_media_image?.resourceIdObjMeta,
  };

  const dropCap =
    fieldData.behavior_settings?.style_options?.drop_cap?.better_css_class ===
    "enabled";

  const itemPosition =
    fieldData.behavior_settings?.style_options?.item_position?.better_css_class;

  let leftComponent;
  switch (fieldData.field_item?.type) {
    case "paragraph--number_longform":
      leftComponent = "number";
      break;
    case "paragraph--image_longform":
      leftComponent = "image";
      break;
    default:
      leftComponent = null;
  }

  const svgArrow = `
<svg xmlns="http://www.w3.org/2000/svg" width="95" height="79" viewBox="0 0 95 79" fill="none">
  <path d="M55.8572 78L94.0001 39.5M94.0001 39.5L55.8572 1M94.0001 39.5H1.00006" stroke="#1A1D2E" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;

  const htmlDescription = `${svgArrow} ${fieldData?.field_description?.processed || ""
    }`;

  return (
    <Box
      className={`longform-maincontent ${styles.longFormMainContent} ${dropCap && styles.dropCap} ${isModal && styles.isModal
        }`}
    >
      {leftComponent ? (
        (fieldData.field_title ||
          fieldData.field_subtitle ||
          leftComponent) && (
          <Box
            className={`${styles.longFormMainContentLeft} ${(dropCap || itemPosition == "bottom" || !leftComponent) &&
              styles.lineBottom
              } ${itemPosition == "top" && styles.lineTop}`}
            justifyContent={
              fieldData.field_title || fieldData.field_subtitle
                ? "space-between"
                : itemPosition == "bottom" && !fieldData.field_title
                  ? "flex-end"
                  : "flex-start"
            }
          >
            {(fieldData.field_title || fieldData.field_subtitle) && (
              <Box className={styles.longFormMainContentLeftUp}>
                {fieldData.field_title && (
                  <Box className={styles.longFormMainContentLeftUpTitle}>
                    <Typography variant="h4" component={"h4"}>
                      {fieldData.field_title}
                    </Typography>
                  </Box>
                )}
                {fieldData.field_subtitle && (
                  <Box className={styles.longFormMainContentLeftUpSubtitle}>
                    <Typography variant="bodyM" component={"p"}>
                      {fieldData.field_subtitle}
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
            {!isMobile && leftComponent && (
              <Box>
                <ViewContainer>
                  {(isInView) => (
                    <LongFormMainContentLeftDown
                      leftComponent={leftComponent}
                      isInView={isInView}
                      leftImage={leftImage}
                      fieldData={fieldData}
                      countUpRef={countUpRef}
                    />
                  )}
                </ViewContainer>
              </Box>
            )}
          </Box>
        )
      ) : (
        <Box className={`${styles.longFormMainContentLeft} ${isModal && styles.hide}`} />
      )}
      <Box
        className={`${styles.longFormMainContentRight} ${rightImage.url ? styles.rightImage : ""
          }`}
      >
        <Box className={styles.longFormMainContentRightText}>
          <Typography
            variant="description"
            component={"div"}
            className={`${dropCap && !isModal ? styles.dropCap : ""} ${styles.description
              }`}
            dangerouslySetInnerHTML={{
              __html:
                isModal && dropCap
                  ? htmlDescription
                  : fieldData?.field_description?.processed,
            }}
          />
        </Box>
        {rightImage.url && (
          <Box className={styles.longFormMainContentRightImage}>
            <Image
              src={rightImage.url}
              alt={rightImage?.alt}
              width={rightImage?.width}
              height={rightImage?.height}
              quality={100}
            />
          </Box>
        )}
      </Box>
      {isMobile && (
        <ViewContainer>
          {(isInView) => (
            <LongFormMainContentLeftDown
              leftComponent={leftComponent}
              isInView={isInView}
              leftImage={leftImage}
              fieldData={fieldData}
              countUpRef={countUpRef}
            />
          )}
        </ViewContainer>
      )}
    </Box>
  );
};

export default LongFormMainContent;
