import React from "react";
import { Box, Typography } from "@mui/material";
import LongFormTriangle from "@components/UI/Icons/LongFormTriangle";
import theme from "@styles/mui/theme";
import styles from "./LongFormScrollingContents.module.scss";

interface LeftSectionProps {
  fieldData: {
    field_label?: string;
    field_title?: string;
    field_subtitle?: { processed: string };
    field_description?: { processed: string };
  };
}

const LeftSection = React.forwardRef<HTMLDivElement, LeftSectionProps>(
  function LeftSection({ fieldData }) {
    return (
      <Box>
        <Box className={styles.scrollingContents__left__top}>
          {fieldData.field_label && (
            <Typography variant="overline" component="span">
              {fieldData.field_label}
            </Typography>
          )}

          {fieldData.field_title && (
            <Typography
              variant="h3"
              component="h3"
              className={styles.scrollingContents__left__title}
            >
              {fieldData.field_title}
            </Typography>
          )}
          {fieldData.field_subtitle && (
            <Typography
              mt={theme.spacing(16)}
              variant="bodyM"
              component="div"
              dangerouslySetInnerHTML={{
                __html: fieldData.field_subtitle.processed,
              }}
            />
          )}
        </Box>
        {fieldData.field_description && (
          <Box className={styles.scrollingContents__left__bottom}>
            <span>
              <LongFormTriangle />

            </span>
            <Typography
              variant="bodyM"
              component="div"
              mt={theme.spacing(16)}
              dangerouslySetInnerHTML={{
                __html: fieldData.field_description.processed,
              }}
            />
          </Box>
        )}
      </Box>
    );
  }
);

export default LeftSection;
