import { DrupalNode } from "next-drupal";
import * as paragraphs from "@components/Paragraph";

export const paragraphsMap = {
  'paragraph--accordion': paragraphs.Accordion,
  'paragraph--anchor': paragraphs.Anchor,
  'paragraph--approccio': paragraphs.Approccio,
  'paragraph--callmenow': paragraphs.Callmenow,
  'paragraph--cards_selection': paragraphs.Cardsselection,
  'paragraph--case_history': paragraphs.Casehistory,
  'paragraph--countdown': paragraphs.Countdown,
  'paragraph--cta': paragraphs.Cta,
  'paragraph--download': paragraphs.Download,
  'paragraph--editorial_split': paragraphs.Editorialsplit,
  'paragraph--entry_point': paragraphs.Entrypoint,
  'paragraph--form': paragraphs.Form,
  'paragraph--hero': paragraphs.Hero,
  'paragraph--image': paragraphs.Image,
  'paragraph--intro': paragraphs.Intro,
  'paragraph--management': paragraphs.Management,
  'paragraph--map': paragraphs.Map,
  'paragraph--modello_operativo': paragraphs.Modellooperativo,
  'paragraph--news_content': paragraphs.Newscontent,
  'paragraph--newsletter': paragraphs.Newsletter,
  'paragraph--numeri': paragraphs.Numeri,
  'paragraph--obbiettivi': paragraphs.Obbiettivi,
  'paragraph--pillar': paragraphs.Pillar,
  'paragraph--servizi': paragraphs.Servizi,
  'paragraph--slider': paragraphs.Slider,
  'paragraph--tab': paragraphs.Tab,
  'paragraph--text': paragraphs.Text,
  'paragraph--title': paragraphs.Title,
  'paragraph--video': paragraphs.Video,
  "paragraph--hero_longform": paragraphs.LongFormHero,
  "paragraph--intro_longform": paragraphs.LongFormIntro,
  "paragraph--main_content_longform": paragraphs.LongFormMainContent,
  "paragraph--accordions_longform": paragraphs.LongFormAccordion,
  "paragraph--image_longform": paragraphs.LongFormImage,
  "paragraph--scrolling_contents_longform": paragraphs.LongFormScrollingContents,
  "paragraph--info_summary_longform": paragraphs.LongFormInfoSummary,
  "paragraph--info_numbered_box_longform": paragraphs.LongFormInfoNumberedBox,
  "paragraph--advantages_list_longform": paragraphs.LongFormAdvantagesList,
  "paragraph--modal_longform": paragraphs.LongFormModal,
}

const common_paragraph_include: string[] = [
  "field_paragraph.field_hero_item.field_immagine.field_media_image",
  "field_paragraph.field_hero_item.field_immagine_darkmode.field_media_image",
  "field_paragraph.field_hero_item.field_image.field_media_image",
  "field_paragraph.field_hero_item.field_image_darkmode.field_media_image",
  "field_paragraph.field_hero_item.field_logo_hero.field_media_image",
  "field_paragraph.field_intro_background_image.field_media_image",
  "field_paragraph.field_intro_icon",
  "field_paragraph.field_case_history_item.field_case_history_image.field_media_image",
  "field_paragraph.field_download_item",
  "field_paragraph.field_download_item.field_download_item_document.field_media_document",
  "field_paragraph.field_accordion_item",
  "field_paragraph.field_accordion_item.field_image.field_media_image",
  "field_paragraph.field_cta_item",
  "field_paragraph.field_slider_item",
  "field_paragraph.field_slider_item.field_slider_item_image.field_media_image",
  "field_paragraph.field_image_asset.field_media_image",
  "field_paragraph.field_newscontent_items",
  "field_paragraph.field_newscontent_items.field_newscontent_img.field_media_image",
  "field_paragraph.field_pillar_item",
  "field_paragraph.field_pillar_item.field_pillar_item_image.field_media_image",
  "field_paragraph.field_pillar_cta",
  "field_paragraph.field_video.field_media_video_file",
  "field_paragraph.field_video_preview.field_media_image",
  "field_paragraph.field_form_item",
  "field_paragraph.field_immagine.field_media_image",
  "field_paragraph.field_immagine.field_media_video_file",
  "field_paragraph.field_approccio_background.field_media_image",
  "field_paragraph.field_approccio_item",
  "field_paragraph.field_tab_item.field_tab_item_col.field_tab_item_col_icon",
  "field_paragraph.field_servizi_background.field_media_image",
  "field_paragraph.field_servizi_image.field_media_image",
  "field_paragraph.field_servizi_item.field_servizi_icon",
  "field_paragraph.field_background.field_media_image",
  "field_paragraph.field_entry_point_item",
  "field_paragraph.field_entry_point_item.field_entry_point_item_image.field_media_image",
  "field_paragraph.field_modello_item",
  "field_paragraph.field_modello_item.field_modello_item_icon",
  "field_paragraph.field_callmenow_item",
  "field_paragraph.field_management_item",
  "field_paragraph.field_management_item.field_management_item_image.field_media_image",
  "field_paragraph.field_items",
  "field_paragraph.field_items.field_image",
  "field_paragraph.field_items.field_image.field_media_image",
  "field_paragraph.field_items.field_immagine.field_media_image",
  "field_paragraph.field_items.field_icon",
  "field_paragraph.field_items.field_icon.field_media_image_1",
  "field_paragraph.field_items.field_node_reference",
  "field_paragraph.field_items.field_node_reference.field_article_image.field_media_image",
  "field_paragraph.field_items.field_node_reference.field_paragraph",
  "field_paragraph.field_items.field_node_reference.field_paragraph.field_hero_item",
];

const longform_paragraph_include: string[] = [
  "field_article_image.thumbnail",
  "field_paragraph.paragraph_type",
  "field_paragraph.field_image",
  "field_paragraph.field_image.field_media_image",
  "field_paragraph.field_image_mobile",
  "field_paragraph.field_image_mobile.field_media_image",
  "field_paragraph.field_item",
  "field_paragraph.field_item.field_image",
  "field_paragraph.field_item.field_image.field_media_image",
  "field_paragraph.field_item.field_image_mobile.field_media_image",
  "field_paragraph.field_items",
  "field_paragraph.field_icon.field_media_image",
];

const modalLongform_paragraph_include: string[] = [
  "field_paragraph.paragraph_type",
  "field_paragraph.field_image",
  "field_paragraph.field_image.field_media_image",
  "field_paragraph.field_image_mobile",
  "field_paragraph.field_image_mobile.field_media_image",
  "field_paragraph.field_item",
  "field_paragraph.field_item.field_image",
  "field_paragraph.field_item.field_image.field_media_image",
  "field_paragraph.field_item.field_image_mobile.field_media_image",
  "field_paragraph.field_items",
  "field_paragraph.field_icon.field_media_image",
];

const taxonomy_term_categoria_articolo_include = [
  "field_paragraphs.field_hero_item.field_immagine.field_media_image",
  "field_paragraphs.field_hero_item.field_immagine_darkmode.field_media_image",
  "field_paragraphs.field_hero_item.field_image.field_media_image",
  "field_paragraphs.field_hero_item.field_image_darkmode.field_media_image",
  "field_paragraphs.field_hero_item.field_logo_hero.field_media_image",
]

const createParagraphInclude = (includes: string[]) => includes.join(",");

export const paragraph_include = {
  "node--page": createParagraphInclude(common_paragraph_include),
  "node--article": createParagraphInclude(common_paragraph_include),
  "node--implants": createParagraphInclude(common_paragraph_include),
  "node--longform": createParagraphInclude(longform_paragraph_include),
  "taxonomy_term--categoria_articolo": createParagraphInclude(taxonomy_term_categoria_articolo_include),
  "node--paragraphs_holder": createParagraphInclude(common_paragraph_include),
  "node--paragraphs_holder-longform": createParagraphInclude(modalLongform_paragraph_include),
};

export function renderParagraphs(node: DrupalNode) {
  const isCategory: boolean = node?.type == "taxonomy_term--categoria_articolo";
  const fieldName = isCategory ? "field_paragraphs" : "field_paragraph";

  const field_paragraphs = Array.isArray(node[fieldName])
    ? node[fieldName]
    : [node[fieldName]];

  const fieldParagraph = field_paragraphs.map((field, i) => {
    const ParagraphComponent = paragraphsMap[field.type];

    return ParagraphComponent ? (
      <ParagraphComponent
        key={field.id}
        fieldData={field}
        node={node}
        index={i}
      />
    ) : null;
  });

  return fieldParagraph;
}
