import Image from 'next/image';
import { NextRouter, useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';

import { getThumbnailUrl, getVideoUrl } from '@helper/media';
import useWindowSize, { WindowSize } from '@hooks/useWindowSize';

import Play from '@components/UI/CustomIcons/Play';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import FadeIn from '@components/Common/FadeIn/FadeIn';
import { DrupalMediaField, DrupalSimpleTextField } from '@model/fields.model';
import { ParagraphProps } from '@model/paragraphs.model';

import styles from './Video.module.scss';
import Grid from '@mui/material/Grid';

interface VideoProps {
  field_video: DrupalMediaField;
  field_video_preview: DrupalMediaField;
  field_video_title?: DrupalSimpleTextField;
  field_video_subtitle?: DrupalSimpleTextField;
  field_video_url?: DrupalSimpleTextField;
  behavior_settings?: {
    style_options: {
      container_width: {
        better_css_class: string | null;
      }
    }
  }
}

export const Video: React.FC<ParagraphProps<VideoProps>> = ({ fieldData }) => {
  const { field_video_title, field_video, field_video_url, field_video_preview, behavior_settings } = fieldData;

  const router: NextRouter = useRouter();
  const size: WindowSize = useWindowSize();
  const [showCover, setShowCover] = useState(field_video_preview != undefined);
  const refImg = useRef<HTMLDivElement | null>(null);
  const refVideo = useRef<HTMLVideoElement | null>(null);
  const refContainer = useRef<HTMLDivElement | null>(null);

  const containerWidth: string | 'full' = behavior_settings?.style_options?.container_width?.better_css_class ?? 'full';
  const isFullWidth = containerWidth === 'full';

  useEffect(() => {
    setShowCover(field_video_preview != undefined)
  }, [field_video_preview, router.asPath])

  useEffect(() => {
    if (!refContainer.current) return;
    refContainer.current.style.minHeight = refVideo.current ? refVideo.current.clientHeight + 'px' : refImg.current.clientHeight + 'px';
  }, [size])

  const localVideo = getVideoUrl(field_video);
  const videoUrl = localVideo ? localVideo : field_video_url;

  const RenderedVideo: React.FC = () => {
    return (
      <>
        {((showCover && field_video_preview) ? (
          <Box ref={refImg}>
            <Image
              className={styles.preview}
              alt={field_video_title ? field_video_title : 'Video placeholder'}
              src={getThumbnailUrl(field_video_preview)}
              width={field_video_preview?.field_media_image?.resourceIdObjMeta?.width}
              height={field_video_preview?.field_media_image?.resourceIdObjMeta?.height}
            />
            <IconButton className={styles.btnPlay} onClick={() => setShowCover(false)}>
              <Play />
            </IconButton>
          </Box>
        ) : (
          <Box>
            <video ref={refVideo}
              className={styles.video}
              autoPlay={!!field_video_preview}
              controls
              controlsList="nodownload"
              playsInline
            >
              <source src={videoUrl} type={field_video?.field_media_video_file?.filemime || "video/mp4"} />
            </video>
          </Box>
        )
        )}
      </>
    )
  }

  return (
    <FadeIn>
      <Container ref={refContainer} disableGutters={isFullWidth} maxWidth={false} className={styles.videoPlayer}>
        <Grid container spacing={0} justifyContent={'center'}>
          <Grid item xs={12} lg={isFullWidth ? 12 : parseInt(containerWidth)}>
            <RenderedVideo />
          </Grid>
        </Grid>
      </Container>
    </FadeIn>
  )
}

export default Video