import { Autoplay, EffectFade, Pagination, SwiperOptions } from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Swiper, SwiperSlide } from 'swiper/react';
import styles from './Hero.module.scss';

import { FC } from "react";
import { ParagraphModel, ParagraphProps } from '@model/paragraphs.model';
import { HeroItemModel, HeroModel, HeroSlideType } from "./Hero.model";
import HeroSlide from "./slide";
import { WindowSize } from "@hooks/useWindowSize";

export const getHeroImage = (slide: HeroSlideType, darkMode: boolean, size: WindowSize) => {
	const { field_image, field_image_darkmode, field_immagine, field_immagine_darkmode } = slide;

	const getImageUrl = (imageField) => imageField?.field_media_image?.uri?.url || '';

	const deskImage = darkMode ? getImageUrl(field_immagine_darkmode) : getImageUrl(field_immagine);
	const mobileImage = darkMode ? getImageUrl(field_image_darkmode) : getImageUrl(field_image);

	if (!size) return '';
	return (size.width < 1024 && size.width < size.height && mobileImage) ? mobileImage : deskImage;
}

const Hero: FC<ParagraphProps<HeroModel>> = ({ fieldData, node }) => {
	const { field_hero_style, field_hero_item = [] } = fieldData

	const params: SwiperOptions = {
		spaceBetween: 0,
		slidesPerView: 1,
		modules: [Pagination, EffectFade, Autoplay],
		effect: "fade",
		fadeEffect: { crossFade: true },
		virtualTranslate: true,
		autoplay: {
			delay: 5000,
			disableOnInteraction: false,
		},
		speed: 1000,
		pagination: {
			clickable: true,
			bulletActiveClass: styles.swiperBulletActive,
			renderBullet: (index: number, className: string): string => `<span class="${className} ${styles.swiperBullet}"></span>`
		},
		loop: true,
	}

	return (
		<Swiper {...params} className={`${styles.heroSwiper} ${styles[field_hero_style]}`} >
			{field_hero_item.map((slide: ParagraphModel & HeroItemModel) => (
				<SwiperSlide key={slide.id} className={`${styles.swiperItem}`} >
					<HeroSlide slide={slide} node={node} />
				</SwiperSlide>
			))}
		</Swiper>
	)
}

export default Hero