// CountUpWithSuffix.tsx
import React, { useRef, useState } from "react";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import CountUp from "react-countup";
import theme from "@styles/mui/theme";

interface CountUpWithSuffixProps {
  end: number;
  prefix?: string;
  suffix?: string;
  duration?: number;
  delay?: number;
  routerLocale: string;
  isInView: boolean;
}

const CountUpWithSuffix: React.FC<CountUpWithSuffixProps> = ({
  end,
  prefix = "",
  suffix = "",
  duration = 2,
  delay = 0.5,
  routerLocale,
  isInView,
}) => {
  const countUpRef = useRef(null);
  const [fixedWidth, setFixedWidth] = useState<number | null>(null);

  const isMobile: boolean = useMediaQuery(useTheme().breakpoints.down("lg"));

  // useEffect(() => {
  //   if (countUpRef.current) {
  //     const width = countUpRef.current.offsetWidth;
  //     setFixedWidth(width);
  //   }
  // }, [countUpRef.current, routerLocale, isInView]);

  return (
    <>
      {prefix && (
        <Typography
          variant="h1"
          component={"p"}
          mr={theme.spacing(16)}
          dangerouslySetInnerHTML={{
            __html: prefix + " ",
          }}
        />
      )}
      <Typography
        variant="h1"
        component={"p"}
        ref={countUpRef}
        style={{
          width: fixedWidth ? `${fixedWidth}px` : "auto",
          display: "inline-block",
        }}
      >
        <CountUp
          separator={routerLocale === "en" ? "," : "."}
          end={end}
          useEasing={true}
          duration={duration}
          delay={delay}
          decimals={
            Math.floor(end) !== end ? String(end).split(".")[1].length : 0
          }
          decimal={routerLocale === "en" ? "." : ","}
        />
      </Typography>
      {suffix && (
        <Typography
          variant="h1"
          component={"p"}
          mr={theme.spacing(16)}
          dangerouslySetInnerHTML={{
            __html: suffix + " ",
          }}
        />
      )}
    </>
  );
};

export default CountUpWithSuffix;
