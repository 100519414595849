import React from "react";
import { Box, Typography, Container, Grid } from "@mui/material";
import { LongFormInfoNumberedBoxProps } from "./LongFormInfoNumberedBox.props";
import Row from "@components/Modules/Utils/Row/Row";
import { ParagraphProps } from "@model/paragraphs.model";
import styles from "./LongFormInfoNumberedBox.module.scss";

const LongFormInfoNumberedBox: React.FC<
  ParagraphProps<LongFormInfoNumberedBoxProps>
> = ({ fieldData }) => {
  const { field_title, field_description, field_number } = fieldData;
  return (
    <Box className={styles.longFormInfoNumberedBox}>
      <Container maxWidth={false} disableGutters>
        <Row justifyContent="flex-end" columnSpacing={0}>
          <Grid item xs={12} lg={7.7} className={styles.gridItem}>

            <Box className={styles.numberedBox}>
              {/* Header */}
              <Box className={styles.headerBox}>
                <Box className={styles.numberCircle}>
                  <Typography variant="h5">{field_number}</Typography>
                </Box>
                <Box>
                  <Box className={styles.titleLine} />
                  <Typography variant="h4">{field_title}</Typography>
                </Box>
              </Box>
              {/* Content */}
              <Box className={styles.contentBox}>
                <Typography
                  variant="description"
                  component={"div"}
                  paragraph
                  dangerouslySetInnerHTML={{
                    __html: field_description?.processed,
                  }}
                ></Typography>
              </Box>
            </Box>
          </Grid>
        </Row>
      </Container>
    </Box>
  );
};

export default LongFormInfoNumberedBox;
