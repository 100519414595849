import FadeIn from "@components/Common/FadeIn/FadeIn";
import { ParagraphModel, ParagraphProps } from "@model/paragraphs.model";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import {
  DrupalMediaField,
  DrupalSimpleTextField,
  DrupalTextField,
} from "@model/fields.model";
import { useState } from "react";
import styles from "./Accordion.module.scss";
import AccordionItem from "./AccordionItem";

interface AccordionProps {
  field_accordion_title: DrupalSimpleTextField;
  field_accordion_item: AccordionItemProps[];
}

export interface AccordionItemProps extends ParagraphModel {
  field_accordion_item_title: DrupalSimpleTextField;
  field_accordion_item_content: DrupalTextField;
  field_image: DrupalMediaField;
}

const AccordionComponent: React.FC<ParagraphProps<AccordionProps>> = ({
  fieldData,
}) => {
  const { field_accordion_title, field_accordion_item } = fieldData;
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <FadeIn>
      <Container maxWidth={false} className={styles.accordion}>
        <Grid container justifyContent={"center"}>
          <Grid item xs={12} lg={10} width={"100%"}>
            <Stack spacing={0}>
              {field_accordion_title && (
                <Typography variant="h2" mb={40}>
                  {field_accordion_title}
                </Typography>
              )}
              {field_accordion_item &&
                field_accordion_item.map(
                  (el: AccordionItemProps, index: number) => (
                    <AccordionItem
                      key={index}
                      panel={`panel${index}`}
                      expanded={expanded}
                      onChange={handleChange(`panel${index}`)}
                      fieldData={el}
                    />
                  )
                )}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </FadeIn>
  );
};

export default AccordionComponent;
