import ArrowDown from "@components/UI/Icons/ArrowDown";
import ArrowLeft from "@components/UI/Icons/ArrowLeft";
import LogoWhite from "@components/UI/Logo/LogoWhite";
import { getImageData } from "@helper/media";
import { removeHtmlTags } from "@helper/parseHTML";
import {
  DrupalMediaField,
  DrupalSimpleTextField,
  DrupalTextField,
} from "@model/fields.model";
import { ParagraphProps } from "@model/paragraphs.model";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import Image from "next/image";
import Link from "next/link";
import styles from "./LongFormHero.module.scss";

interface HeroLongFormProps {
  field_title: DrupalSimpleTextField;
  field_subtitle: DrupalSimpleTextField;
  field_description: DrupalTextField;
  field_label: DrupalSimpleTextField;
  field_image: DrupalMediaField;
  field_image_mobile: DrupalMediaField;
}

const LongFormHero: React.FC<ParagraphProps<HeroLongFormProps>> = ({ fieldData }) => {
  const isMobile: boolean = useMediaQuery(useTheme().breakpoints.down("lg"));

  const scrollDown = () => {
    const nextSection = document.querySelector(`.${styles.longFormHero}`).nextElementSibling;
    if (nextSection) {
      nextSection.scrollIntoView({ behavior: "smooth" });
    } else {
      const viewportHeight = window.innerHeight;
      window.scrollBy({
        top: viewportHeight,
        behavior: "smooth",
      });
    }
  };

  return (
    <Box className={styles.longFormHero}>
      {fieldData.field_image && (
        <Box className={styles.imageWrapper}>
          <Image
            fetchPriority="high"
            src={getImageData(
              !isMobile
                ? fieldData.field_image
                : fieldData.field_image_mobile || fieldData.field_image
            )}
            alt={
              !isMobile
                ? fieldData.field_image?.name || "Background Image"
                : fieldData.field_image_mobile?.name ||
                fieldData.field_image?.name ||
                "Background Image"
            }
            fill
            priority={true}
            style={{ objectFit: "cover" }}
          />
        </Box>
      )}
      <Box className={styles.longFormHeroBackButton} component={Link} href={"/"}>
        <ArrowLeft />
      </Box>
      <Box className={styles.longFormHeroContainer}>
        <Box>
          <Box className={styles.longFormHeroLogoBox}>
            <LogoWhite />
          </Box>
          {fieldData.field_title && (
            <Box className={styles.longFormHeroTitle}>
              <Typography variant="h1" component={"h1"}>
                {fieldData?.field_title}
              </Typography>
            </Box>
          )}
        </Box>
        <Box className={styles.longFormHeroContent}>
          <Box className={styles.longFormHeroSubtitleSection}>
            {fieldData.field_subtitle && (
              <Box className={styles.longFormHeroSubtitle}>
                <Typography variant="h2" component="h2">
                  {fieldData.field_subtitle}
                </Typography>
              </Box>
            )}
            {fieldData.field_label && (
              <Box className={styles.longFormHeroYearBox}>
                <Typography
                  className={styles.longFormHeroYearLabel}
                  variant="caption"
                  component={"span"}
                >
                  {fieldData.field_label}
                </Typography>
              </Box>
            )}
          </Box>
          <Box className={styles.longFormHeroDescription}>
            {fieldData.field_description && (
              <Typography
                className={styles.longFormHeroDescriptionText}
                variant="bodyM"
                component="div"
              >
                {removeHtmlTags(fieldData.field_description.processed)}
              </Typography>
            )}
            {!isMobile && (
              <Box className={styles.longFormHeroScroll}>
                <Box
                  className={styles.longFormHeroScrollContent}
                  onClick={scrollDown}
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  <Box>
                    <Typography
                      variant="bodyS"
                      component={"span"}
                      textTransform={"uppercase"}
                    >
                      {"Scroll Down"}
                    </Typography>
                  </Box>
                  <Box
                    className={styles.longFormHeroScrollButton}
                    component="button"
                    sx={{
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <ArrowDown />
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LongFormHero;
