import { SvgIcon, SvgIconProps } from "@mui/material";

const LongFormCloseIcon = ({
  stroke,
  ...otherProps
}: { stroke: string } & SvgIconProps) => {
  return (
    <SvgIcon
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <g id="Group 430">
        <circle
          id="Ellipse 1"
          cx="20"
          cy="20"
          r="20"
          transform="rotate(-90 20 20)"
          fill="#DBDDE2"
        />
        <path
          id="+"
          d="M20.5838 19.8052L28.4853 27.7067L27.7068 28.4851L19.8054 20.5837L11.7093 28.6798L10.9309 27.9013L19.0269 19.8052L11.1255 11.9038L11.904 11.1253L19.8054 19.0268L27.9014 10.9307L28.6799 11.7092L20.5838 19.8052Z"
          fill="#1A1D2E"
        />
      </g>
    </SvgIcon>
  );
};

export default LongFormCloseIcon;
