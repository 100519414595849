import { absoluteUrl } from '@helper/url';
import Image from 'next/image';
import { FC, useContext } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Row from '@components/Modules/Utils/Row/Row';
import { DarkModeContext } from '@contexts/darkMode';
import useWindowSize, { WindowSize } from '@hooks/useWindowSize';

import { CustomCountdown } from '@components/Common/CustomCountdown';
import { useTranslation } from 'next-i18next';
import styles from '@components/Paragraph/Hero/Hero.module.scss';
import { SlideItemProps } from '../Hero.model';
import { getHeroImage } from '../Hero';

const SlideDefault: FC<SlideItemProps> = ({ slide }) => {
  const { t } = useTranslation();
  const { darkMode } = useContext(DarkModeContext);
  const size: WindowSize = useWindowSize();

  const { id, field_title, field_copyright, field_date, field_description, field_immagine, field_link, field_secondary_link, field_logo_hero, } = slide

  const buttonVariant = {
    primary: 'contained',
    secondary: 'outlined'
  }

  const imageSlide: string = getHeroImage(slide, darkMode, size);
  const logoImage: string = field_logo_hero?.field_media_image?.uri?.url;

  const getButtonVariant = (type) => buttonVariant[slide?.[type]?.options?.attributes?.cta_preset_class] || null;
  const primaryButtonVariant = getButtonVariant('field_link') || 'contained';
  const secondaryButtonVariant = getButtonVariant('field_secondary_link') || 'link';

  return (
    <Box className={styles.heroWrapper}>
      <Box className={styles.heroWrapperContent}>
        <Container maxWidth={false} className={styles.container}>

          <Row className={styles.row}>
            <Grid item xs={false} lg={1} />
            <Grid className={`${styles.content} swiper-no-swiping`} item xs={12} lg={7} >
              {logoImage && (
                <Image
                  className={styles.logoImage}
                  src={absoluteUrl(logoImage)}
                  width={field_logo_hero?.field_media_image?.resourceIdObjMeta?.width}
                  height={field_logo_hero?.field_media_image?.resourceIdObjMeta?.height}
                  alt={`logo hero`}
                  quality={100}
                  priority
                />
              )}

              <Typography
                className={`${styles.title} swiper-no-swiping`}
                variant="h3"
                component="h1"
                mt={4}
              >
                {field_title}
              </Typography>

              <Typography
                className={styles.desc}
                dangerouslySetInnerHTML={{
                  __html: field_description?.value,
                }}
                style={{ display: "block" }}
                variant="bodyM"
                component="div"
                mt={24}
                mb={40}
              />

              {field_date && typeof field_date === "string" && (
                <CustomCountdown
                  date={field_date}
                  timeToHide={["seconds"]}
                />
              )}

              {(field_link?.url && field_link?.title != t("register")) && (
                <Button
                  id={`${id}`}
                  variant={primaryButtonVariant}
                  href={field_link?.url}
                >
                  {field_link?.title}
                </Button>
              )}

              {(field_link?.url.startsWith("#") || !field_link?.url) && field_link?.title == t("register") && (
                <Button
                  id={`${id}_primary`}
                  variant={primaryButtonVariant}
                  onClick={() => {
                    const anchor = document.querySelector(field_link?.url ?
                      field_link?.url : "#registratiCta"
                    );

                    anchor.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                    });
                  }}
                >
                  {field_link?.title}
                </Button>
              )}

              {field_secondary_link?.url && (
                <Box
                  mt={{ xs: 24, lg: 32 }}
                  textAlign={{ xs: "center", lg: "left" }}
                >
                  <Button
                    id={`${id}_secondary`}
                    variant={secondaryButtonVariant}
                    href={field_secondary_link?.url}
                  >
                    {field_secondary_link?.title}
                  </Button>
                </Box>
              )}

              {field_copyright?.value && (
                <Typography
                  className={`${styles.copyright} swiper-no-swiping`}
                  dangerouslySetInnerHTML={{
                    __html: field_copyright?.value,
                  }}
                  style={{ display: "block" }}
                  variant="bodyL"
                  component="div"
                />
              )}

            </Grid>
          </Row>

        </Container>
      </Box>

      {field_immagine && (
        <Image
          className={styles.heroImage}
          width={field_immagine?.field_media_image?.resourceIdObjMeta?.width}
          height={field_immagine?.field_media_image?.resourceIdObjMeta?.height}
          src={absoluteUrl(imageSlide)}
          alt={field_title}
          quality={90}
          priority
        />
      )}
    </Box>
  );
}

export default SlideDefault