import { ParagraphModel, ParagraphProps } from "@model/paragraphs.model";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import {
  DrupalMediaField,
  DrupalSimpleTextField,
  DrupalTextField,
} from "@model/fields.model";
import { useState } from "react";
import styles from "./LongFormAccordion.module.scss";
import LongFormAccordionItem from "./LongFormAccordionItem";
import { useMediaQuery, useTheme } from "@mui/material";

interface AccordionProps {
  field_title: DrupalSimpleTextField;
  field_items: AccordionItemProps[];
}

export interface AccordionItemProps extends ParagraphModel {
  field_title: DrupalSimpleTextField;
  field_description: DrupalTextField;
  field_image: DrupalMediaField;
}

const LongFormAccordion: React.FC<ParagraphProps<AccordionProps>> = ({
  fieldData,
}) => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const isMobile: boolean = useMediaQuery(useTheme().breakpoints.down("lg"));

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Container
      maxWidth={false}
      className={`${styles.accordion}`}
      disableGutters={true}
    >
      <Grid container justifyContent={"flex-end"}>
        <Grid item xs={12} lg={8.45} width={"100%"}>
          <Stack spacing={0}>
            {/* {fieldData.field_title && <Typography variant="h2" mb={40}>{fieldData.field_title}</Typography>} */}
            {fieldData.field_items &&
              fieldData.field_items.map((el: any, index: number) => (
                <LongFormAccordionItem
                  key={index}
                  panel={`panel${index}`}
                  expanded={expanded}
                  onChange={handleChange(`panel${index}`)}
                  fieldData={el}
                  index={index}
                />
              ))}
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default LongFormAccordion;
