import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { useMediaQuery, useTheme } from "@mui/material";

gsap.registerPlugin(ScrollTrigger);

type Params = {
  leftSectionRef: React.RefObject<HTMLElement>;
  rightSectionRef: React.RefObject<HTMLElement>;
  contRef: React.RefObject<HTMLElement>;
};

const debounce = (func, delay) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  };
};

const useLongFormScroll = ({
  leftSectionRef,
  rightSectionRef,
  contRef,
}: Params) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  useGSAP(
    () => {
      if (isMobile) {
        ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
        return;
      }

      if (
        !leftSectionRef.current ||
        !rightSectionRef.current ||
        !contRef.current
      )
        return;

      const rightSection = rightSectionRef.current;
      const cont = contRef.current;

      const trigger = ScrollTrigger.create({
        trigger: cont,
        start: "top top",
        endTrigger: rightSection,
        end: "bottom bottom",
        pin: true,
        pinSpacing: true,
        scrub: false,
        toggleActions: "play reverse play reverse",
        markers: false,
        invalidateOnRefresh: true, // Ricalcola valori su refresh
      });

      const onResize = debounce(() => ScrollTrigger.refresh(), 100);
      window.addEventListener("resize", onResize);

      return () => {
        trigger.kill();
        window.removeEventListener("resize", onResize);
      };
    },
    {
      dependencies: [leftSectionRef, rightSectionRef, contRef, isMobile],
      scope: contRef,
      revertOnUpdate: true,
    }
  );
};

export default useLongFormScroll;
