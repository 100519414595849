import Row from "@components/Modules/Utils/Row/Row";
import LongFormHalfDot from "@components/UI/Icons/LongFormHalfDot";
import { ParagraphProps } from "@model/paragraphs.model";
import { Box, Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import styles from "./LongFormAdvantagesList_1.module.scss";
import { LongFormAdvantagesListProps } from "./LongFormAdvantagesList_1.props";

const LongFormAdvantagesList: React.FC<
  ParagraphProps<LongFormAdvantagesListProps>
> = ({ fieldData, node, isModal }) => {
  const { field_title, field_items } = fieldData;

  const isLongForm = !isModal;

  return (
    <Box
      className={`${styles.longformAdvantagesList} ${isModal && styles.isModal
        }`}
    >
      <Container maxWidth={false} disableGutters>
        <Row justifyContent="flex-end">
          <Grid item xs={12} lg={isLongForm ? 7.7 : 12}>
            <Box
              className={`${styles.borderedWrapper} ${isLongForm && styles.isLongForm
                }`}
            >
              {field_title && (
                <Box className={styles.headerBox}>
                  <Box className={styles.line} />
                  <Typography variant="description">
                    {field_title}
                  </Typography>
                </Box>
              )}
              {field_items?.length ? (
                <Row flexDirection="column">
                  {field_items.map((item, idx) => (
                    <Grid item xs={12} lg={10} key={idx}>
                      <Box className={styles.listBox}>
                        <LongFormHalfDot />
                        <Typography
                          variant="description"
                          component={"div"}
                          className={styles.smallText}
                          dangerouslySetInnerHTML={{
                            __html: item.field_text_content?.processed,
                          }}
                        ></Typography>
                      </Box>
                    </Grid>
                  ))}
                </Row>
              ) : null}
            </Box>
          </Grid>
        </Row>
      </Container>
    </Box>
  );
};

export default LongFormAdvantagesList;
