import { SvgIcon, SvgIconProps } from "@mui/material";

const LongFormPlusIcon = ({
  stroke,
  width,
  ...otherProps
}: { stroke: string } & SvgIconProps) => {
  return (
    <SvgIcon
      width={width}
      height={width}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <g id="Group 428">
        <circle
          id="Ellipse 1"
          cx="20"
          cy="20"
          r="20"
          transform="rotate(90 20 20)"
          fill="#DBDDE2"
        />
        <path
          id="+"
          d="M19.2262 20.3263L8.05193 20.3263L8.05193 19.2254L19.2262 19.2254L19.2262 7.77588L20.3272 7.77588L20.3272 19.2254L31.5015 19.2254L31.5015 20.3263L20.3272 20.3263L20.3272 31.7759L19.2262 31.7759L19.2262 20.3263Z"
          fill="#1A1D2E"
        />
      </g>
    </SvgIcon>
  );
};

export default LongFormPlusIcon;
