// LongFormModal.tsx
import React, { useState } from "react";
import { Box } from "@mui/material";
import styles from "./LongFormModal.module.scss";
import LongFormModalContent from "./LongFormModalContent";
import LongFormContent from "./LongFormContent";
import { LongFormModalProps } from "./LongFormModal.props";
import { ParagraphProps } from "@model/paragraphs.model";

const LongFormModal: React.FC<ParagraphProps<LongFormModalProps>> = ({
  fieldData,
  node,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Box className={styles.longFormModal}>
        <LongFormContent fieldData={fieldData} modal={false} node={node} onClick={handleOpen} />
      </Box>

      <LongFormModalContent
        open={open}
        handleClose={handleClose}
        fieldData={fieldData}
        modal={true}
        node={node}
      />
    </>
  );
};

export default LongFormModal;
