
export default function ArrowDown(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
    >
      <g clipPath="url(#clip0_6793_101)">
        <path
          d="M7 14L7 0.5M7 14L13.5 7M7 14L0.5 7"
          stroke="#1A1D2E"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6793_101">
          <rect
            width="15"
            height="14"
            fill="white"
            transform="translate(14) rotate(90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
