import { DrupalNode } from "next-drupal"
import Image from "next/legacy/image";

import {getThumbnailAlt, getThumbnailUrl} from "@helper/media";
import { formatDate } from "@helper/date";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import EastIcon from '@mui/icons-material/East';

import styles from './Article.module.scss';
import Link from "next/link";
import { useTranslation } from "next-i18next";

interface ArticleTeaserProps {
  node: DrupalNode
}

export default function ArticleTeaser({ node }: ArticleTeaserProps) {
  const {t} = useTranslation();
  const image = node?.field_article_image ? getThumbnailUrl(node.field_article_image) : '';
  const date = formatDate(node?.created, node.path.langcode)

  return (
    
    <Link href={node?.path?.alias} passHref>
      <Box className={styles.articleCard}>
        {image && <Image src={image} alt={getThumbnailAlt(node.field_article_image)} width={392} height={220} layout="responsive" objectFit="cover" quality={100} />}
        
        <Box className={styles.content}>
          <Typography variant="bodyM" fontWeight="light" color="text.secondary">{date}</Typography>
          <Typography variant="subtitle" component='h5' className={styles.title}>{node.title}</Typography>
        </Box>

        <Box className={styles.button}>
          <Button 
            variant="link"
            startIcon={<EastIcon color="primary" />}
          >{t("news.readAll")}</Button>
        </Box>

      </Box>
    </Link>
  )
}