import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { getThumbnailUrl } from '@helper/media'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import useMediaQuery from '@mui/material/useMediaQuery'
import theme from '@styles/mui/theme'
import Image from 'next/image'
import { AccordionItemProps } from './Accordion'
import styles from './Accordion.module.scss'

interface AccordionItemModel {
  panel: string;
  expanded: string | false;
  onChange: (_event: React.SyntheticEvent, isExpanded: boolean) => void;
  fieldData: AccordionItemProps;
}

export default function AccordionItem({ panel, expanded, onChange, fieldData }: AccordionItemModel) {
  const {field_accordion_item_title, field_accordion_item_content, field_image} = fieldData;
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const mapColumn = {
    top: 12,
    bottom: 12,
    left: 6,
    right: 6
  }
  
  const mapDirection = {
    top: 'column',
    bottom: 'column-reverse',
    left: 'row',
    right: 'row-reverse'
  }

  const layout = {
    desktop: fieldData?.behavior_settings?.style_options?.accordion_item_image_pos?.better_css_class,
    mobile: fieldData?.behavior_settings?.style_options?.accordion_item_image_pos_mobile?.better_css_class
  }

  const image ={
    url: getThumbnailUrl(field_image),
    ...field_image?.field_media_image?.resourceIdObjMeta
  };

  const direction = mapDirection[ isMobile ? layout.mobile : layout.desktop];
  const colSize: 6 | 12 = image?.url ? mapColumn[isMobile ? layout.mobile : layout.desktop] : 12;

  return (
    <Accordion expanded={expanded === panel} onChange={onChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon color={'primary'} fontSize="large"/>}
        aria-controls={`${panel}a-content`}
        id={`${panel}a-header`}
      >
        <Typography variant="subtitle" fontWeight={700}>
          {field_accordion_item_title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>

      <Grid container justifyContent={"center"} direction={direction}>
        {image?.url &&
          <Grid item xs={colSize} lg={colSize}>
            <Image 
              src={image?.url}
              alt={field_accordion_item_title}
              width={image.width}
              height={image.height}
              quality={100}
              className={styles.image}
            />
          </Grid>
        }

        <Grid item xs={colSize} lg={colSize}>
          <Typography
            variant="bodyL"
            component="div"
            dangerouslySetInnerHTML={{ __html: field_accordion_item_content?.processed }}
            className={styles.accordionContent}
          />
        </Grid>
      </Grid>
      </AccordionDetails>
    </Accordion>
  );
}