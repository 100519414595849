import React, { useRef } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import styles from "./LongFormScrollingContents.module.scss";
import { Box } from "@mui/material";
import { ScrollingContentsProps } from "./LongFormScrollingContents.props";
import Row from "@components/Modules/Utils/Row/Row";
import Container from "@mui/material/Container";
import useLongFormScroll from "@hooks/useLongFormScroll";
import LeftSection from "./LeftSection";
import LongFormHalfDot from "@components/UI/Icons/LongFormHalfDot";
import FadeIn from "@components/Common/FadeIn/FadeIn";
import { ParagraphProps } from "@model/paragraphs.model";
// import RightSectionItem from "./RightSectionItem";

const LongFormScrollingContents: React.FC<ParagraphProps<ScrollingContentsProps>> = ({ fieldData }) => {

  const { field_items } = fieldData;

  const leftSectionRef = useRef<HTMLDivElement>(null);
  const rightSectionRef = useRef<HTMLDivElement>(null);
  const contRef = useRef<HTMLDivElement>(null);

  useLongFormScroll({ leftSectionRef, rightSectionRef, contRef });

  return (
    <Box className={styles.scrollingContentsWrap}>
      <FadeIn>
        {/* Sezione sinistra fissa o sticky */}
        <Box className={`${styles.scrollingContents} ${styles.scrollingContentsL}`} ref={contRef}>
          <Row>
            <Grid item xs={12} lg={4} className={styles.scrollingContents__left} ref={leftSectionRef}>
              <LeftSection ref={leftSectionRef} fieldData={fieldData} />
            </Grid>
          </Row>
        </Box>

        {/* Sezione destra scrollabile */}
        <Container maxWidth={false} className={`${styles.scrollingContents} ${styles.scrollingContentsR}`} disableGutters>
          <Row>
            <Grid item xs={12} lg={8} className={styles.scrollingContents__right} ref={rightSectionRef}>
              {field_items?.length > 0 &&
                field_items.map((item, idx) => (
                  <Grid item xs={12} lg={8.45} key={idx}>
                    <Box className={styles.scrollingContents__right__cont}>
                      <Box display={"flex"} alignItems={"center"}>
                        <LongFormHalfDot />
                        <Typography variant="h2" component="h4" className={styles.scrollingContents__right__title}>
                          {item.field_title}
                        </Typography>
                      </Box>
                      <Typography
                        className={styles.scrollingContents__right__description}
                        variant="bodyM"
                        component="div"
                        dangerouslySetInnerHTML={{
                          __html: item.field_description?.processed,
                        }}
                      />
                    </Box>
                  </Grid>
                ))}
            </Grid>
          </Row>
        </Container>
      </FadeIn>
    </Box>
  );
}

export default LongFormScrollingContents