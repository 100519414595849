import { DrupalSimpleTextField } from "@model/fields.model";
import { ParagraphProps } from "@model/paragraphs.model";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import ViewContainer from "@components/Common/ViewContainer/ViewContainer";
import CountUpWithSuffix from "@components/Modules/CountUpWithSuffix/CountUpWithSuffix";
import Row from "@components/Modules/Utils/Row/Row";
import RoundedArrow from "@components/UI/Icons/RoundedArrow";
import { removeHtmlTags } from "@helper/parseHTML";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import XIcon from "@mui/icons-material/X";
import theme from "@styles/mui/themeLongForm";
import { useRouter } from "next/router";
import styles from "./LongFormIntro.module.scss";

interface IntroLongFormProps {
  behavior_settings: any;
  field_title: DrupalSimpleTextField;
  field_description: any;
  field_side_title: DrupalSimpleTextField;
  field_items: any;
}

const LongFormIntro: React.FC<ParagraphProps<IntroLongFormProps>> = ({ fieldData, node }) => {
  const router = useRouter();

  const { behavior_settings, field_title, field_description, field_side_title, field_items } = fieldData;
  const {
    title,
    field_socialshare_email_object: field_email_object,
    field_socialshare_email_body: field_email_body,
  } = node;

  const socialShare = behavior_settings?.style_options?.social_share?.better_css_class === "visible";

  const url = process.env.NEXT_PUBLIC_BASE_URL + router.asPath;

  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const isDown1230 = useMediaQuery(theme.breakpoints.down("xlg"));

  const iconProps = { sx: { color: theme.palette.secondary.dark } };

  const emailObject = field_email_object ? field_email_object : title;
  const emailBody = field_email_body ? `${field_email_body} ${url}` : url;

  const socials = [
    {
      type: "LinkedIn",
      url: `https://www.linkedin.com/sharing/share-offsite/?url=${url}`,
      icon: <LinkedInIcon {...iconProps} />,
    },
    {
      type: "Twitter",
      url: `https://twitter.com/intent/tweet?original_referer=${url}&text=${title}&url=${url}`,
      icon: <XIcon {...iconProps} />,
    },
    {
      type: "WhatsApp",
      url: isMobile ? `whatsapp://send?text=${title}: ${url}` : `https://api.whatsapp.com/send?text=${title}: ${url}`,
      attributes: {
        "data-action": "share/whatsapp/share",
      },
      icon: <WhatsAppIcon {...iconProps} />,
    },
    {
      type: "Facebook",
      url: `https://www.facebook.com/sharer/sharer.php?u=${url}&title=${title}`,
      icon: <FacebookIcon {...iconProps} />,
    },
    {
      type: "Email",
      url: `mailto:?subject=${emailObject}&body=${emailBody}`,
      icon: <AlternateEmailIcon {...iconProps} />,
    },
  ];

  return (
    <Box className={`${styles.longFormIntro}`}>
      <Grid container sx={{ marginLeft: "0 !important" }}>
        <Row
          sx={{
            marginLeft: "0 !important",
            borderLeft: `1px solid ${theme.palette.secondary.dark}`,
            paddingLeft: { xs: theme.spacing(8), lg: theme.spacing(16) },
            width: { xs: "calc(100% - 1rem)", lg: "calc(100% + 1rem)" },
          }}
        >
          <Grid
            item
            xs={12}
            lg={3}
            xl={4}
            className={styles.longFormIntroLeftSideContainer}
            paddingLeft={"0 !important"}
          >
            <Box className={styles.longFormIntroLeftSide}>
              <Box className={styles.longFormIntroLeftSideTop}>
                {socialShare && (
                  <Box className={styles.longFormIntroShare}>
                    <Box className={styles.longFormIntroShareTitle}>
                      <Typography variant="overline" component={"span"}>
                        {"SHARE"}
                      </Typography>
                    </Box>

                    <Stack
                      className={styles.longFormIntroShareSocials}
                      direction="row"
                      spacing={{ xs: 24, sm: 24, md: 12, xlg: 18, xl: 24 }}
                      divider={
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{ borderColor: theme.palette.secondary.dark }}
                        />
                      }
                    >
                      {socials.map((social, index) => (
                        <Box key={"social_" + index} >
                          <Link href={social.url} display={"flex"} target="_blank">
                            {social.icon}
                          </Link>
                        </Box>
                      ))}
                    </Stack>
                  </Box>
                )}
                <Box className={styles.longFormIntroChapterContainer}>
                  <Box className={styles.longFormIntroChapter}>
                    <Box className={styles.longFormIntroChapterTitle}>
                      <Typography variant="overline" component={"span"}>
                        {"CAPITOLO"}
                      </Typography>
                    </Box>
                    <Box className={styles.longFormIntroChapterContent}>
                      <Typography variant="h2" component={"h2"}>
                        {node.field_chapter}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={styles.longFormIntroReadingTime}>
                    <Box className={styles.longFormIntroReadingTimeTitle}>
                      <Typography variant="overline" component={"span"}>
                        {"TEMPO DI LETTURA"}
                      </Typography>
                    </Box>
                    <Box className={styles.longFormIntroReadingTimeContent}>
                      <Typography variant="bodyL" component={"p"}>
                        {node.field_reading_time}’
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className={styles.longFormIntroTitle}>
                <Typography
                  variant="h4"
                  component={"h4"}
                  marginTop={theme.spacing(16)}
                >
                  {field_side_title}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid
            xs={12}
            item
            lg={9}
            xl={8}
            paddingLeft={"0 !important"}
            className={styles.longFormIntroRightSideContainer}
          >
            <Box className={styles.longFormIntroRightSide}>
              <Box className={styles.longFormIntroRightSideUp}>
                {!isMobile && (
                  <Box className={styles.longFormIntroRightSideUpArrow}>
                    <RoundedArrow />
                  </Box>
                )}
                <Box className={styles.longFormIntroRightSideUpText}>
                  <Box className={styles.longFormIntroRightSideUpTitle}>
                    <Typography
                      variant="h3"
                      component={"h3"}
                      fontSize={isDown1230 && `${theme.spacing(40)} !important`}
                    >
                      {field_title}
                    </Typography>
                  </Box>
                  <Box className={styles.longFormIntroRightSideUpDesc}>
                    <Typography variant="caption" component={"p"}>
                      {removeHtmlTags(field_description.value)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <ViewContainer>
                {(isInView) => (
                  <Box className={styles.longFormIntroRightSideItems}>
                    {field_items?.map((item, index) => {
                      return (
                        <Box
                          key={index}
                          className={styles.longFormIntroRightSideItem}
                          marginLeft={index != 0 && theme.spacing(32)}
                          width={`${100 / field_items?.length}%`}
                        >
                          {item?.field_number && isInView && (
                            <Box className={styles.longFormIntroRightSideItemTitle}>
                              <CountUpWithSuffix
                                end={item.field_number}
                                suffix={item.field_suffix || ""}
                                prefix={item.field_prefix || ""}
                                isInView={isInView}
                                routerLocale={router.locale}
                              />
                            </Box>
                          )}
                          <Box className={styles.longFormIntroRightSideItemDesc}>
                            <Typography variant="caption" component={"p"}>
                              {removeHtmlTags(item?.field_description.value)}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                )}
              </ViewContainer>
            </Box>
          </Grid>
        </Row>
      </Grid>
    </Box>
  );
};

export default LongFormIntro;
