import React from 'react';

interface HalfDotProps extends React.SVGProps<SVGSVGElement> {}

export default function LongFormHalfDot(props: HalfDotProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10 20C7.34783 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 -5.53044e-07 12.6522 -4.37114e-07 10C-3.21184e-07 7.34784 1.05357 4.8043 2.92893 2.92893C4.80429 1.05357 7.34783 -1.52578e-07 10 -4.37114e-07L10 10L10 20Z"
        fill="#30B762"
      />
      <path
        d="M10 1.19249e-07C12.6522 1.50876e-07 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34783 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20L10 10L10 1.19249e-07Z"
        fill="#1A1D2E"
      />
    </svg>
  );
}
