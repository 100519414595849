import Image from 'next/image';

import { getThumbnailAlt, getThumbnailUrl } from "@helper/media";

import FadeIn from '@components/Common/FadeIn/FadeIn';
import { ParagraphProps } from '@model/paragraphs.model';
import styles from './Image.module.scss';

interface ImageAsset {
  field_media_image: {
    resourceIdObjMeta: {
      width: number;
      height: number;
    };
  };
}

interface ImageProps {
  field_image_asset: ImageAsset;
}

const ImageParagraph: React.FC<ParagraphProps<ImageProps>> = ({ fieldData }) => {
  const { field_image_asset } = fieldData;

  const url = getThumbnailUrl(field_image_asset);
  const alt = getThumbnailAlt(field_image_asset);
  const { width, height } = field_image_asset?.field_media_image?.resourceIdObjMeta || { width: 0, height: 0 };

  if (!field_image_asset || !field_image_asset?.field_media_image) return null;

  return (
    <FadeIn>
      <div className={styles.imageWrapper}>
        <Image
          src={url}
          alt={alt}
          width={width}
          height={height}
          className={styles.image}
        />
      </div>
    </FadeIn>
  );
}

export default ImageParagraph