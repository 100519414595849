import { Box, Typography } from "@mui/material";
import styles from "./LongFormMainContent.module.scss";
import Image from "next/image";
import { useRouter } from "next/router";
import theme from "@styles/mui/theme";
import CountUpWithSuffix from "@components/Modules/CountUpWithSuffix/CountUpWithSuffix";

interface LongFormMainContentLeftDownProps {
  leftComponent: string;
  isInView: boolean;
  leftImage: any;
  fieldData: any;
  countUpRef: any;
}

const LongFormMainContentLeftDown: React.FC<LongFormMainContentLeftDownProps> = ({
  leftComponent,
  isInView,
  leftImage,
  fieldData,
  countUpRef,
}) => {
  const router = useRouter();

  return (
    <Box className={styles.longFormMainContentLeftDown}>
      {leftComponent === "number" && isInView ? (
        <Box className={styles.longFormMainContentLeftNumber}>
          <Box className={styles.longFormMainContentLeftNumberTitle}>
            <CountUpWithSuffix
              end={fieldData?.field_item?.field_number}
              prefix={fieldData?.field_item?.field_prefix}
              suffix={fieldData?.field_item?.field_suffix}
              routerLocale={router.locale}
              isInView={isInView}
            />
          </Box>
          <Box className={styles.longFormMainContentLeftNumberDesc}>
            <Typography
              variant="bodyM"
              component={"div"}
              color={theme.palette.grey["100"]}
              dangerouslySetInnerHTML={{
                __html: fieldData.field_item?.field_description?.value,
              }}
            />
          </Box>
        </Box>
      ) : (
        leftImage.url && (
          <Box
            className={styles.longFormMainContentLeftImage}
            sx={{
              width: "100%",
              margin: "0 auto",
            }}
          >
            <Image
              src={leftImage.url}
              alt={leftImage?.alt}
              width={leftImage?.width}
              height={leftImage?.height}
              layout="responsive"
            />
          </Box>
        )
      )}
    </Box>
  );
};

export default LongFormMainContentLeftDown;
