import { SvgIcon, SvgIconProps } from "@mui/material";

const LongFormCloseIcon = ({...otherProps}: SvgIconProps) => {
  return (
    <SvgIcon
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path d="M8.41615 9.19476L0.514719 1.29333L1.29319 0.514859L9.19462 8.41629L17.2907 0.320242L18.0691 1.09871L9.97308 9.19476L17.8745 17.0962L17.096 17.8747L9.19462 9.97322L1.09857 18.0693L0.320102 17.2908L8.41615 9.19476Z" fill="#1A1D2E"/>
    </SvgIcon>
  );
};

export default LongFormCloseIcon;
