import { useInView } from "framer-motion";
import { useRef } from "react";
import { ViewContainerProps } from "./ViewContainer.props";
import { Box } from "@mui/material";

const ViewContainer = (props: ViewContainerProps) => {
  const { children, once = true, root, margin, amount } = props;

  const ref = useRef<HTMLDivElement>(null);

  const isInView = useInView(ref, {
    once,
    root,
    margin,
    amount,
  });

  return (
    <Box height="100%" ref={ref}>
      {children(isInView)}
    </Box>
  );
};

export default ViewContainer;
