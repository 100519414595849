import Image from "next/image";

import { getThumbnailAlt, getThumbnailUrl, getUrlAttributes } from "@helper/media";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Row from '@components/Modules/Utils/Row/Row';

import { ParagraphProps } from '@model/paragraphs.model';
import styles from './Obbiettivi.module.scss';

import FadeIn from "@components/Common/FadeIn/FadeIn";
import { ObbiettiviProps } from "./Obbiettivi.model";
import Obbiettivo from './Obbiettivo';
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Link from "next/link";
import { useTranslation } from "next-i18next";

const Obbiettivi: React.FC<ParagraphProps<ObbiettiviProps>> = ({ fieldData }) => {

  const {
    field_background,
    field_content,
    field_theme,
    field_overlay,
    field_title,
    field_items,
    field_copyright,
    field_link,
    behavior_settings
  } = fieldData

  const { t } = useTranslation();

  const layout = behavior_settings?.style_options?.obbiettivi_layout?.better_css_class || 'flex'

  const background = field_background && getThumbnailUrl(field_background);
  const overlay = field_overlay;
  const title = field_title;
  const content = field_content?.value;

  const colSize = layout == 'layout-2' ? 6 : 3

  return (
    <FadeIn>
      <Container
        maxWidth={false}
        className={`paragraph_obbiettivi ${styles.obiettiviWrapper} ${background && styles.background} ${field_theme ? styles.themeLight : styles.themeDark} ${background ? 'paragraph_obbiettivi_background' : ''} ${overlay ? 'paragraph_obbiettivi_overlay' : ''}`}
      >
        {overlay ? <div className={`${styles.overlay} ${background ? styles.overlayBg : ''}`} /> : null}
        {background && (
          <Image
            src={getThumbnailUrl(field_background)}
            alt={getThumbnailAlt(field_background)}
            fill
            style={{ zIndex: '-1', objectFit: 'cover' }}
          />
        )}

        {(title || content) &&
          <Row justifyContent={'center'} sx={{ marginBottom: { xs: 92, lg: 64 } }}>
            <Grid className={styles.content} item lg={9} textAlign="center">
              {title ? <Typography variant="h2" component="h2" mb={24} className={styles.title}>{title}</Typography> : <div className={styles.title}></div>}
              {content && <Typography variant="bodyL" component="div" className={styles.desc} dangerouslySetInnerHTML={{ __html: content }} />}
            </Grid>
          </Row>
        }

        <Row rowSpacing={{ xs: 64 }} columnSpacing={{ lg: 28 }} justifyContent={'center'}>
          {field_items?.map((obbiettivo, i) => (
            <Grid
              key={obbiettivo.id}
              item
              lg={layout == 'layout-2' && i == 0 ? 12 : colSize}
              xs={12}
            >
              <FadeIn transition={{ delay: 0.2 * (i + 1) }}>
                <Obbiettivo data={obbiettivo} light={!!field_theme} />
              </FadeIn>
            </Grid>
          ))}
        </Row>

        {field_link?.url && (
          <Box display="flex" justifyContent="center" mt={40}>
            <Button
              LinkComponent={Link}
              {...getUrlAttributes(field_link)}
              variant={field_link?.options?.attributes?.cta_preset_class || "contained"}
            >
              {field_link?.title || t("discoverMore")}
            </Button>
          </Box>
        )}

        {field_copyright?.value && (
          <Typography
            className={styles.copyright}
            dangerouslySetInnerHTML={{ __html: field_copyright?.value }}
            variant="bodyL"
            component="div"
          />
        )}

      </Container>
    </FadeIn>
  )
}

export default Obbiettivi