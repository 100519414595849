import Image from "next/image";

import { getImageData } from "@helper/media";

import "swiper/css";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import styles from "./Slider.module.scss";
import { SlideItemProps } from "./types";

const VideoPodcastSlide: React.FC<{ slide: SlideItemProps }> = ({ slide }) => {
  const {
    field_slider_item_iframe: iframe,
    field_description: description
  } = slide;

  if (!iframe) {
    return null
  }

  return (
    <Box className={`${styles.content} ${styles.iframeWrapper}`}>
      <Box
        className={`${styles.panel} ${styles[slide.field_slider_item_type]}`}
        dangerouslySetInnerHTML={{ __html: slide?.field_slider_item_iframe }}
      />
      {description?.value && <Typography component="div" variant="bodyS" dangerouslySetInnerHTML={{ __html: description?.value }} />}
    </Box>
  )
}

const ImageSlide: React.FC<{ slide: SlideItemProps }> = ({ slide }) => {

  const {
    field_slider_item_image: image,
    field_title: title,
    field_description: description,
  } = slide;

  if (!image) {
    return null
  }

  return (
    <Box className={`${styles.content} ${styles.imageWrapper}`}>
      <Image
        className={styles.image}
        {...getImageData(image)}
        sizes="(max-width: 768px) 100vw, (max-width: 1200px) 80vw, 50vw"
      />
      {(title || description?.value) && (
        <Box className={styles.imageContent}>
          {title && <Typography component="h5" variant="h5" fontWeight={700} dangerouslySetInnerHTML={{ __html: title }} />}
          {description?.value && <Typography component="div" variant="bodyS" dangerouslySetInnerHTML={{ __html: description?.value }} />}
        </Box>
      )}
    </Box>

  )
}

const SlideContent: React.FC<{ slide: SlideItemProps }> = ({ slide }) => {

  const slideContentMap = {
    image: ImageSlide,
    video: VideoPodcastSlide,
    podcast: VideoPodcastSlide
  }

  const Component = slideContentMap[slide.field_slider_item_type] || ImageSlide;

  return (
    <Component slide={slide} />
  );
};

export default SlideContent