import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { getThumbnailUrl } from "@helper/media";
import useMediaQuery from "@mui/material/useMediaQuery";
import theme from "@styles/mui/theme";
import Image from "next/image";
import { AccordionItemProps } from "./LongFormAccordion";
import styles from "./LongFormAccordion.module.scss";
import { Box } from "@mui/material";
import LongFormPlusIcon from "@components/UI/Icons/LongFormPlusIcon";
import LongFormCloseIcon from "@components/UI/Icons/LongFormCloseIcon";

interface AccordionItemModel {
  panel: string;
  expanded: string | false;
  onChange: (event: React.SyntheticEvent, isExpanded: boolean) => void;
  fieldData: AccordionItemProps;
  index: number;
}

export default function LongFormAccordionItem({
  panel,
  expanded,
  onChange,
  fieldData,
  index,
}: AccordionItemModel) {
  const { field_title, field_description, field_image } = fieldData;
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const mapColumn = {
    top: 12,
    bottom: 12,
    left: 6,
    right: 6,
  };

  const mapDirection = {
    top: "column",
    bottom: "column-reverse",
    left: "row",
    right: "row-reverse",
  };

  const layout = {
    desktop:
      fieldData?.behavior_settings?.style_options?.accordion_item_image_pos
        ?.better_css_class,
    mobile:
      fieldData?.behavior_settings?.style_options
        ?.accordion_item_image_pos_mobile?.better_css_class,
  };

  const image = {
    url: getThumbnailUrl(field_image),
    ...field_image?.field_media_image?.resourceIdObjMeta,
  };

  const direction = mapDirection[isMobile ? layout.mobile : layout.desktop];
  const colSize: 6 | 12 = image?.url
    ? mapColumn[isMobile ? layout.mobile : layout.desktop]
    : 12;


  return (
    <Accordion
      expanded={expanded === panel}
      onChange={onChange}
      className={styles.accordionItem}
    >
      <AccordionSummary
        expandIcon={
          expanded === panel ? (
            <LongFormCloseIcon stroke={"primary"} height={isMobile ? 32 : 40} />
          ) : (
            <LongFormPlusIcon stroke={"primary"} height={isMobile ? 32 : 40} />
          )
        }
        aria-controls={`${panel}a-content`}
        id={`${panel}a-header`}
      >
        <Box className={styles.titleLine} />
        <Typography variant="h3" component={"h3"} fontWeight={700}>
          {field_title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container justifyContent={"center"} direction={direction}>
          {image?.url && (
            <Grid item xs={colSize} lg={colSize}>
              <Image
                src={image?.url}
                alt={field_title}
                width={image.width}
                height={image.height}
                quality={100}
                className={styles.image}
              />
            </Grid>
          )}

          <Grid item xs={12} lg={4}>
            <Typography
              variant="bodyL"
              component="div"
            >
              {index + 1}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Typography
              variant="bodyM"
              component="div"
              dangerouslySetInnerHTML={{
                __html: field_description?.processed,
              }}
              className={styles.accordionContent}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
