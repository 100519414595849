import FadeIn from "@components/Common/FadeIn/FadeIn";
import Newsletter from "@components/Common/Form/Newsletter";
import { DrupalSimpleTextField, DrupalTextField } from "@model/fields.model";

import { ParagraphProps } from "@model/paragraphs.model";

interface NewsletterProps {
  field_title?: DrupalSimpleTextField;
  field_subtitle?: DrupalTextField;
}

const NewsletterParagraph: React.FC<ParagraphProps<NewsletterProps>> = ({ fieldData }) => {

  const {field_title = null, field_subtitle = null} = fieldData

  return (
    <FadeIn id={fieldData.id}>
      <Newsletter
        title={field_title}
        subtitle={field_subtitle?.processed}
      />
    </FadeIn>
  )
}

export default NewsletterParagraph