import useOnScreen from '@hooks/useOnScreen';
import { useEffect, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Row from '@components/Modules/Utils/Row/Row';

import CountUp from 'react-countup';

import * as AnimatedSvg from '../../UI/AnimatedSvg/index';

import { useTranslation } from 'next-i18next';
import styles from './Numeri.module.scss';

export default function Numeri({ fieldData }) {
  const { t } = useTranslation();

  return (
    <div className={styles.numeriBackground}>
      <div className={styles.numeriWrapper}>
        <div className={styles.numeriItem0}>
          <Counter num={3} duration={1} />
          <Typography variant='h5' className={styles.text} dangerouslySetInnerHTML={{ __html: t('paragraphs.numeri.country') }} />
        </div>
        <div className={styles.numeriItem0Animation}>
          <div className={styles.animationWrapper}>
            <Animation icon="Progress" />
          </div>

          <Row>
            <Grid item xs={4}>
              <Typography variant='overline'>{t('paragraphs.numeri.italy')}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='overline'>{t('paragraphs.numeri.spain')}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='overline'>{t('paragraphs.numeri.poland')}</Typography>
            </Grid>
          </Row>

        </div>
        <div className={styles.numeriItem1}>
          <Counter num={3600} suffix="+" />
          <Typography variant='h5' className={styles.text}>{t('paragraphs.numeri.people')}</Typography>
        </div>
        <div className={styles.numeriItem10}>
          <Counter num={55000} suffix="+" />
          <Typography variant='h5' className={styles.text} dangerouslySetInnerHTML={{ __html: t('paragraphs.numeri.energyConsultancy') }} />
        </div>
        <div className={styles.numeriItem2}>
          <Animation icon="Pallini" />
        </div>
        <div className={styles.numeriItem6}>
          <Counter num={320} suffix="+" duration={1} />
          <Typography variant='h5' className={styles.text} dangerouslySetInnerHTML={{ __html: t('paragraphs.numeri.servedCities') }} />
        </div>

        <div className={styles.numeriItem11}>
          <Counter num={1.3} suffix=" mln" decimal="," decimals={1} />
          <Typography variant='h5' className={styles.text} dangerouslySetInnerHTML={{ __html: t('paragraphs.numeri.lightSpots') }} />
        </div>

        <div className={styles.numeriItem7}>
          <Counter num={37} />
          <Typography variant='h5' className={styles.text} dangerouslySetInnerHTML={{ __html: t('paragraphs.numeri.districtHeatingNetworks') }} />
        </div>


        <div className={styles.numeriItem5}>
          <Animation icon="Linee" />
        </div>
        <div className={styles.numeriItem3}>
          <Counter num={110} suffix="+" duration={1} />
          <Typography variant='h5' className={styles.text} dangerouslySetInnerHTML={{ __html: t('paragraphs.numeri.productionSites') }} />
        </div>
        <div className={styles.numeriItem4}>
          <Counter num={2500} suffix="+" />
          <Typography variant='h5' className={styles.text} dangerouslySetInnerHTML={{ __html: t('paragraphs.numeri.publicStructures') }} />
        </div>
        <div className={styles.numeriItem8}>
          <Animation icon="Cerchio" />
        </div>
        <div className={styles.numeriItem9}>
          <Counter num={290000} />
          <Typography variant='h5' className={styles.text} dangerouslySetInnerHTML={{ __html: t('paragraphs.numeri.tonsWaste') }} />
        </div>
      </div>
      {fieldData?.field_numeri_cta?.url &&
        <Container maxWidth={false}>
          <Box display="flex" justifyContent="center">
            <Button
              id='cta'
              variant="contained"
              href={fieldData?.field_numeri_cta?.url}
            >{fieldData?.field_numeri_cta?.title}</Button>
          </Box>
        </Container>
      }
    </div>
  )
}

function Counter({ num, duration = 2, ...props }) {
  const ref: any = useRef(null);
  const onScreen: boolean = useOnScreen(ref);

  const [loaded, setLoadead] = useState(false);
  useEffect(() => {
    if (onScreen) setLoadead(true);
  }, [onScreen])

  return (
    <Typography variant='h2' className={styles.title} ref={ref}>
      {loaded ?
        <CountUp
          {...props}
          end={num}
          separator={props.separator || '.'}
          duration={duration}
        /> : null}
    </Typography>
  )
}

function Animation({ icon }) {
  const Icon = AnimatedSvg[icon];

  const iconRef: any = useRef(null);
  const onScreen: boolean = useOnScreen(iconRef);

  const [loaded, setLoadead] = useState(false);
  useEffect(() => {
    if (onScreen) setLoadead(true);
  }, [onScreen])

  return (
    <div ref={iconRef}>
      {loaded && <Icon />}
    </div>
  )
}