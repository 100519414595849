import Image from "next/legacy/image";
import { useEffect, useMemo, useRef, useState } from 'react';

import { getThumbnailAlt, getThumbnailUrl } from "@helper/media";

import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from "@mui/material/Stack";
import Typography from '@mui/material/Typography';

import FadeIn from "@components/Common/FadeIn/FadeIn";
import Row from "@components/Modules/Utils/Row/Row";

import { DrupalLinkField, DrupalMediaField, DrupalTextField } from "@model/fields.model";
import { ParagraphModel, ParagraphProps } from "@model/paragraphs.model";
import { useTranslation } from 'next-i18next';
import styles from './CaseHistory.module.scss';

interface CaseHistoryProps {
  field_case_history_item: CaseHistoryItemProps[];
  field_case_history_filter: boolean;

}
interface CaseHistoryItemProps extends ParagraphModel {
  field_case_history_type: 'company' | 'pa';
  field_case_history_background: 'none' | 'primary' | 'secondary';
  field_case_history_copyright: DrupalTextField;
  field_case_history_title: DrupalTextField;
  field_case_history_subtitle: DrupalTextField;
  field_case_history_content: DrupalTextField;
  field_case_history_image: DrupalMediaField;
  field_case_history_image_opt: 'left' | 'right';
  field_case_history_text: 'left' | 'center' | 'right';
  field_case_history_url: DrupalLinkField;
}

type CaseHistoryType = 'company' | 'pa' | 'all';
const CaseHistory: React.FC<ParagraphProps<CaseHistoryProps>> = ({ fieldData }) => {
  const { field_case_history_item, field_case_history_filter: hasFilter } = fieldData;
  const { t } = useTranslation();

  const chipType1 = useRef(null);
  const chipType2 = useRef(null);

  const filterClick = (_e, type: Omit<CaseHistoryType, 'all'>) => {
    let typeFilter: CaseHistoryType = "all";
    if (type === 'company') {
      chipType1.current.classList.toggle("activeFilter")
      if (chipType2.current.classList.contains("activeFilter")) {
        typeFilter = "pa";
      } else {
        typeFilter = "company";
      }
    }

    if (type === 'pa') {
      chipType2.current.classList.toggle("activeFilter")
      if (chipType1.current.classList.contains("activeFilter")) {
        typeFilter = "company";
      } else {
        typeFilter = "pa";
      }
    }

    if ((chipType1.current.classList.contains("activeFilter") && chipType2.current.classList.contains("activeFilter")) || (!chipType2.current.classList.contains("activeFilter") && !chipType1.current.classList.contains("activeFilter"))) {
      typeFilter = "all";
    }
    setTypes(typeFilter);
    let arrItems = fieldData.field_case_history_item;
    arrItems = arrItems.filter(function (item, index) {
      return typeFilter == item.field_case_history_type || item.field_case_history_type === null || typeFilter == "all";
    });
    setLoadMoreOn(arrItems.length > 3);
    setNext(3);
  }

  const [types, setTypes] = useState<CaseHistoryType>('all');
  const [loadMoreOn, setLoadMoreOn] = useState<boolean>(field_case_history_item?.length > 3 && hasFilter ? true : false);
  const maxItems: number = hasFilter ? 3 : field_case_history_item?.length;
  const [next, setNext] = useState<number>(maxItems);

  const handleLoadMore = () => {
    setNext((prev) => prev + 3);
  };

  useEffect(() => {
    if (next >= field_case_history_item?.length) {
      setLoadMoreOn(false);
    }
  }, [field_case_history_item?.length, next])

  const items = useMemo(() => {
    if (types === 'all') {
      return field_case_history_item;
    }
    return field_case_history_item.filter(el => el.field_case_history_type === types);
  }, [field_case_history_item, types]);

  return (
    <>
      {hasFilter && (
        <Container maxWidth={false} className={styles.caseHistoryFilter}>
          <div className={styles.filterCont}>
            <Typography variant="overline" className={styles.filterTitle}>{t('news.filters.filterFor')}</Typography>

            <Stack direction="row" gap={{ xs: 8, lg: 16 }} className={styles.filterChips}>
              <Chip onClick={(e) => filterClick(e, 'company')} ref={chipType1} label={t("companies")} size="small" />
              <Chip onClick={(e) => filterClick(e, 'pa')} ref={chipType2} label={t("publicAdministrations")} size="small" />
            </Stack>
          </div>
        </Container>
      )}

      <Box className={styles.containItems}>
        {items?.slice(0, next).map((item) => <BoxCaseHistory key={item.id} {...item} />)}
      </Box>

      {loadMoreOn &&
        <Box className={styles.loadMore}>
          <Button className={styles.loadMoreBtn}
            onClick={handleLoadMore}
            variant="text"
          >{t("news.loadMore")}</Button>
        </Box>
      }
    </>
  )
}

export default CaseHistory;

function BoxCaseHistory(props: CaseHistoryItemProps) {
  const {
    field_case_history_background,
    field_case_history_type,
    field_case_history_image,
    field_case_history_image_opt,
    field_case_history_text,
    field_case_history_title,
    field_case_history_subtitle,
    field_case_history_content,
    field_case_history_url,
    field_case_history_copyright,
    id
  } = props;

  const { t } = useTranslation();

  const refContainer = useRef(null);
  let classNameBg = field_case_history_background;
  let classNameAlign = 'align_' + field_case_history_text;
  let classNamePosition = 'img_' + field_case_history_image_opt;

  return (
    <Container data-type={field_case_history_type} maxWidth={false} className={`${styles.caseHistoryWrapper} ${styles[classNameBg]}`}>
      <FadeIn>
        <Row spacing={0}>
          <Grid item sm={12} className={`${styles.content} ${styles[classNameBg]} ${styles[classNamePosition]}`}>

            <Box ref={refContainer} className={`${styles.boxCont} ${styles[classNameAlign]}`} >

              {field_case_history_subtitle && (
                <Typography variant="overline" component="p" className={styles.overline}>
                  {field_case_history_subtitle?.processed}
                </Typography>
              )}
              {field_case_history_title && (
                <Typography variant="h4" component="h3" className={styles.title}>
                  {field_case_history_title?.processed}
                </Typography>
              )}
              {field_case_history_content && (
                <Typography
                  variant="bodyL"
                  component="div"
                  className={styles.desc}
                  dangerouslySetInnerHTML={{ __html: field_case_history_content.processed }}
                />
              )}

              {field_case_history_url &&
                <Button id={id} variant={'outlined'} href={field_case_history_url?.url}>
                  {t("discoverMore")}
                </Button>
              }
            </Box>

            {field_case_history_image &&
              <Box className={`${styles.boxCont} ${styles.boxImg}`}>
                <Box className={styles.image}>
                  <Image
                    src={getThumbnailUrl(field_case_history_image)}
                    alt={getThumbnailAlt(field_case_history_image)}
                    layout='fill'
                    objectFit="cover"
                  />

                  {field_case_history_copyright?.value && (
                    <Typography
                      className={styles.copyright}
                      dangerouslySetInnerHTML={{ __html: field_case_history_copyright?.value }}
                      style={{ display: "block" }}
                      variant="bodyL"
                      component="div"
                    />
                  )}

                </Box>
              </Box>
            }
          </Grid>
        </Row>
      </FadeIn>
    </Container>
  )
}
