import { ParagraphProps } from "@model/paragraphs.model";
import { DrupalMediaField, DrupalTextField } from "@model/fields.model";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import styles from "./LongFormImage.module.scss";
import Image from "next/image";
import { getImageData } from "@helper/media";
import FadeIn from "@components/Common/FadeIn/FadeIn";

interface ImageLongFormProps {
  field_description: DrupalTextField;
  field_image: DrupalMediaField;
  field_image_mobile: DrupalMediaField;
}

const LongFormImage: React.FC<ParagraphProps<ImageLongFormProps>> = ({
  fieldData,
  isModal
}) => {
  const isMobile: boolean = useMediaQuery(useTheme().breakpoints.down("lg"));

  const image_size =
    fieldData?.behavior_settings?.style_options?.image_size?.better_css_class;

  const [layoutClass, setLayoutClass] = useState<string>("");

  useEffect(() => {
    switch (image_size) {
      case "boxed":
        setLayoutClass(styles.boxedImage);
        break;
      case "full-width":
        setLayoutClass(styles.fullWidthImage);
        break;
      case "four-fifths":
        setLayoutClass(styles.fourFifthsImage);
        break;
      default:
        setLayoutClass("");
        break;
    }
  }, [image_size]);

  const imageUrl = getImageData(
    fieldData.field_image?.field_media_image?.uri
      ? !isMobile
        ? fieldData.field_image
        : fieldData.field_image_mobile || fieldData.field_image
      : ""
  );

  return (
    <Box className={`longform-image ${styles.longformImage} ${isModal ? styles.isModal : ''}`}>
      <FadeIn>
        <Box className={`${styles.longFormImage} ${layoutClass}`}>
          {fieldData.field_image && (
            <Box className={styles.imageWrapper}>
              {imageUrl && (
                <Image
                  src={imageUrl || ""}
                  alt={
                    !isMobile
                      ? fieldData.field_image?.name || "Background Image"
                      : fieldData.field_image_mobile?.name ||
                        fieldData.field_image?.name ||
                        "Background Image"
                  }
                  width={1920}
                  height={1080}
                  quality={100}
                />
              )}
            </Box>
          )}
        </Box>
      </FadeIn>
      {fieldData.field_description && (
        <FadeIn>
          <Box className={styles.longFormImageDescription}>
            <Typography
              variant="bodyM"
              dangerouslySetInnerHTML={{
                __html: fieldData.field_description?.processed,
              }}
            ></Typography>
          </Box>
        </FadeIn>
      )}
    </Box>
  );
};

export default LongFormImage;
