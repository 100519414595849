
import SlideDefault from "./SlideDefault";
import SlideArticle from "./SlideArticle";
import { SlideItemProps } from "../Hero.model";

const HeroSlide: React.FC<SlideItemProps> = ({ node, ...otherProps }) => {

  const nodeType = {
    "node--page": SlideDefault,
    "node--article": SlideArticle,
    "node--implant": SlideDefault,
  }

  const Component = nodeType[node.type] || SlideDefault;
  return <Component node={node} {...otherProps} />;
}

export default HeroSlide