export default function RoundedArrow(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="104"
      height="211"
      viewBox="0 0 104 211"
      fill="none"
    >
      <path
        d="M103 1.75C103.414 1.75 103.75 1.41421 103.75 1C103.75 0.585786 103.414 0.25 103 0.25L103 1.75ZM103.53 205.53C103.823 205.237 103.823 204.763 103.53 204.47L98.7574 199.697C98.4645 199.404 97.9896 199.404 97.6967 199.697C97.4038 199.99 97.4038 200.464 97.6967 200.757L101.939 205L97.6967 209.243C97.4038 209.536 97.4038 210.01 97.6967 210.303C97.9896 210.596 98.4645 210.596 98.7574 210.303L103.53 205.53ZM103 0.25C69.5467 0.25 44.0325 13.0873 26.7951 32.3752C9.5697 51.6497 0.640435 77.3294 0.262529 102.989C-0.115385 128.649 8.0579 154.338 25.1024 173.622C42.1593 192.92 68.0545 205.75 103 205.75L103 204.25C68.4516 204.25 42.9774 191.58 26.2263 172.628C9.46268 153.662 1.38916 128.351 1.76237 103.011C2.13558 77.6706 10.9554 52.3503 27.9136 33.3748C44.8598 14.4127 69.9638 1.75 103 1.75L103 0.25Z"
        fill="#F6F7F8"
      />
    </svg>
  );
}
