import React from "react";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";

import LongFormModalCloseIcon from "@components/UI/Icons/LongFormModalCloseIcon";
import { DrupalNode } from "next-drupal";
import LongFormContent from "./LongFormContent"; // Import del contenuto
import styles from "./LongFormModal.module.scss";

interface LongFormModalContentProps {
  open: boolean;
  handleClose: () => void;
  fieldData: any;
  node: DrupalNode,
  modal?: boolean;
}

const LongFormModalContent: React.FC<LongFormModalContentProps> = ({
  open,
  handleClose,
  fieldData,
  modal,
  node,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: { timeout: 300 },
      }}
      className={styles.modal}
    >
      <Fade in={open} timeout={500}>
        <Box className={`${styles.longFormModalContent} long-form`}>
          <IconButton onClick={handleClose} className={styles.closeButton}>
            <LongFormModalCloseIcon />
          </IconButton>
          <LongFormContent fieldData={fieldData} modal={modal} node={node} onClick={null} />
        </Box>
      </Fade>

    </Modal>
  );
};

export default LongFormModalContent;
