export default function ArrowLeft(props) {
  const { width = "24", height = "21", fill = "#1A1D2E"} = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 11.152L3.09091 11.152L11.7045 19.7543L10.7727 20.6974L0.590907 10.4929L10.7727 0.311078L11.7045 1.22017L3.09091 9.83381L24 9.8338L24 11.152Z"
        fill={fill}
      />
    </svg>
  );
}
