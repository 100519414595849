import getFormSchema from '@helper/form/formSchema';
import useOnScreen from '@hooks/useOnScreen';
import { NextRouter, useRouter } from "next/router";
import React, { useEffect, useRef, useState } from 'react';

import Container from '@mui/material/Container';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';

import Row from "@components/Modules/Utils/Row/Row";

import styles from './Form.module.scss';

import CustomFormTemplate from "@components/Common/Form/Template/CustomFormTemplate";
import componentMapper from "@data-driven-forms/mui-component-mapper/component-mapper";
import { FormRenderer } from "@data-driven-forms/react-form-renderer";

import Loader from '@components/Common/Loader/Loader';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

import EnhancedSelect from '@components/Common/Form/Fields/EnhancedSelect';
import FileUploadComponent, { fileSizeValidator } from '@components/Common/Form/Fields/InputUpload';
import PlainText from '@components/Common/Form/Fields/PlainText';
import { submitForm } from '@lib/hubspot/form';

interface ParagraphItemModel {
  fieldData: {
    field_form_id: string;
    field_form_item: {
      id: string;
      field_form_item_id: string;
      field_form_item_title: string;
      field_form_item_static: boolean;
      field_form_item_typ_title: string;
      field_form_item_typ_message: string;
    }[]
  };
}

const isFormPerSalesCase = (router: NextRouter) => router?.asPath === '/form-per-sales/';
const formPerSalesCompanyFields = ['ruolo', 'settore___azienda_solo_per__form_b2b'];
const removeCompanyFields = (formValues: {[key: string]: any}) => Object.entries(formValues).reduce(
  (acc: {[key: string]: any}, item: [string, any]) => {
    const [key, value] = item;

    if (formPerSalesCompanyFields.includes(key)) return acc;

    return {...acc, [key]: value};
  },
  {}
);
const formPerSalesPaFields = ['ruolo___pa', 'settore___pa_solo_per_form_b2g', 'codice_ipa'];
const removePAFields = (formValues: {[key: string]: any}) => Object.entries(formValues).reduce(
  (acc: {[key: string]: any}, item: [string, any]) => {
    const [key, value] = item;

    if (formPerSalesPaFields.includes(key)) return acc;

    return {...acc, [key]: value};
  },
  {}
);

export const Form: React.FC<ParagraphItemModel> = ({ fieldData: { field_form_item } }) => {

  const router = useRouter();
  const ref: any = useRef(null);
  const onScreen: boolean = useOnScreen(ref);
  const [loaded, setLoadead] = useState(false);
  const [current, setCurrent] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(false);

  const [data, setData] = useState(null)
  const [schema, setSchema] = useState(null)
  const [query, setQuery] = useState(null)

  useEffect(() => {
    if (onScreen) setLoadead(true);
  }, [onScreen])

  useEffect(() => {
    setSchema(null)
    
    fetch(`/api/hubspot/form/${field_form_item[current].field_form_item_id}`)
      .then((res) => res.json())
      .then((data) => { setData(data) })
  }, [current])

  useEffect(() => {
    const currentFormItem = field_form_item[current];
  
    if (currentFormItem) {
      setSchema(getFormSchema(data, router));
  
      const { field_form_item_typ_title: title, field_form_item_typ_message: message } = currentFormItem;
      const query = (title || message) ? { ...(title && { title: encodeURIComponent(title) }), ...(message && { message: encodeURIComponent(message) }) } : null;
      setQuery(query);
    }
  }, [data, router, current, field_form_item]);

  const validatorMapper = { 'file-size': fileSizeValidator, };
  const customComponentMapper = { ...componentMapper, 'file-upload': FileUploadComponent, 'enhanced-select': EnhancedSelect, 'plain-text': PlainText };

  const handleSubmit = async (values) => {
    let formValues = values;
    
    // Handle form-per-sales unwanted fields 
    if (isFormPerSalesCase(router)) {
      if (values?.azienda_o_pubblica_amministrazione_ === 'Azienda') {
        formValues = removePAFields(values as {[key: string]: any});
      }
      if (values?.azienda_o_pubblica_amministrazione_ === 'Ente Pubblico') {
        formValues = removeCompanyFields(values as {[key: string]: any});
      }
    }

    const submission = await submitForm(
      field_form_item[current].field_form_item_id, 
      formValues, 
      router
    );
    if(submission.status === 200) {
      router.push({ pathname: '/thankyou', query : query });
    }
  }

  return (
    <Fade ref={ref} in={onScreen} appear={onScreen} timeout={1000} style={{ transitionDelay: '100ms' }}>
      {/* TODO: make the id attribute dynamic from paragraph */}
      <Container className={`${styles.formContainer} contactUs-form`} maxWidth={false}>
        <div id="contactform" className={styles.contactFormAnchor} />
        <Row spacing={20} justifyContent={'center'}>

          {field_form_item.length > 1 &&
            <Grid item lg={8}>
              <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="center" spacing={16}>
                {field_form_item.map((el, i) => <Button key={el.id} variant={current == i ? 'contained' : 'outlined'} onClick={() => {setCurrent(i)}}>{el.field_form_item_title}</Button>)}
              </Stack>
            </Grid>
          }

          <Grid item lg={8}>
            {schema ? 
              <FormRenderer
                schema={schema}
                componentMapper={customComponentMapper}
                FormTemplate={({ formFields }) => <CustomFormTemplate formFields={formFields} submitLabel={data?.displayOptions?.submitButtonText} />}
                validatorMapper={validatorMapper}
                onSubmit={(values) =>{
                  setLoader(true);
                  handleSubmit(values);
                }}
              /> : <Skeleton variant="rectangular" height={400} animation="wave" />
            }
            {loader && <Loader active={loader} />}

          </Grid>
        </Row>
      </Container>

    </Fade>
  )
}

export default Form