import { SvgIcon, SvgIconProps } from "@mui/material";

const LongFormSummaryArrow = ({
  fill = "#323239",
  width = 73,
  height = 8,
  ...otherProps
}: {
  fill?: string;
  width?: number;
  height?: number;
} & SvgIconProps) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 73 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M72.3536 4.35355C72.5488 4.15829 72.5488 3.84171 72.3536 3.64645L69.1716 0.464466C68.9763 0.269204 68.6597 0.269204 68.4645 0.464466C68.2692 0.659728 68.2692 0.976311 68.4645 1.17157L71.2929 4L68.4645 6.82843C68.2692 7.02369 68.2692 7.34027 68.4645 7.53553C68.6597 7.7308 68.9763 7.7308 69.1716 7.53553L72.3536 4.35355ZM0 4.5H72V3.5H0V4.5Z"
        fill={fill}
      />
    </SvgIcon>
  );
};

export default LongFormSummaryArrow;
